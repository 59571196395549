import React, { useId } from 'react';

import classnames from 'classnames/bind';

import styles from './EmailInput.module.scss';

const cx: CX = classnames.bind(styles);

function EmailInput({
    value, //
    onChange,
    type,
    disabled,
    ...attributes
}: Props) {
    const inputId = useId();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            onChange(event.target.value, event);
        }
    }

    return (
        <div className={cx('email-input')}>
            <label htmlFor={inputId}>Your Email</label>

            <input
                id={inputId}
                type="text"
                className={cx({
                    error: type === 'error',
                })}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                autoComplete="off"
                aria-autocomplete="none"
                {...attributes}
            />
        </div>
    );
}

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    value: string;
    onChange: (val: string, event: React.ChangeEvent<HTMLInputElement>) => void;
    type: 'default' | 'error';
}

export default EmailInput;
