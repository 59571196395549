import React from 'react';

import classnames from 'classnames/bind';

import { ReactComponent as MLogo } from '@ICONS/M-LOGO.svg';

import styles from './Brand.module.scss';

const cx: CX = classnames.bind(styles);

function Brand() {
    return (
        <div className={cx('brand')}>
            <div className={cx('content')}>
                <div className={cx('title')}>
                    {'Welcome to\nDocument Management Tool'}
                </div>
                <div className={cx('logo')}>
                    <MLogo />
                </div>
            </div>
        </div>
    );
}

export default Brand;
