import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PREFIX, BASE_URL } from '@CONSTANTS/API.constant';
import { GLOBAL_PERMISSIONS } from '@CONSTANTS/PERMISSIONS.constant';

export type User = {
    id: number;

    fullName: string;

    email: string;

    mobilePhone: string;

    officePhone: string;

    country: string;

    title: string;
};

export type GlobalPermission = {
    id: number;

    name: string;

    codename: GLOBAL_PERMISSIONS;
};

export type UserPermissions = {
    isSuperuser: boolean;

    global: GlobalPermission[];
};

type LoginApiResponse = {
    token: string;
};

type LoginParams = {
    email: string;

    token: string;
};

export const authApi = createApi({
    reducerPath: 'auth-api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL + API_PREFIX,
    }),
    endpoints: (builder) => ({
        login: builder.mutation<LoginApiResponse, LoginParams>({
            query: (params) => {
                const formData = JSON.stringify({
                    email: params.email,
                    token: params.token,
                });

                return ({
                    url: '/auth/',
                    method: 'POST',
                    body: formData,
                });
            },
        }),
    }),
});

export const {
    useLoginMutation: useLogin,
} = authApi;
