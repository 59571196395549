import React, { useId, useRef, useState } from 'react';

import classnames from 'classnames/bind';

import { ReactComponent as EyeIcon } from '@ICONS/eye-icon.svg';

import styles from './PasswordInput.module.scss';

const cx: CX = classnames.bind(styles);

function PasswordInput({
    value, //
    onChange,
    type,
    disabled,
    ...attributes
}: Props) {
    const [showPassword, setShowPassword] = useState(false);

    const ref = useRef<HTMLInputElement>(null);

    const inputId = useId();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!disabled) {
            onChange(event.target.value, event);
        }
    }

    return (
        <div className={cx('password-input')}>
            <label htmlFor={inputId}>Password</label>

            <input
                ref={ref}
                id={inputId}
                type={showPassword ? 'text' : 'password'}
                className={cx({
                    error: type === 'error',
                })}
                value={value}
                disabled={disabled}
                onChange={handleChange}
                autoComplete="off"
                aria-autocomplete="none"
                {...attributes}
            />
            <div className={cx('eye')}>
                <button
                    type="button"
                    className="clear-button"
                    onClick={() => {
                        setShowPassword((val: boolean) => !val);

                        setTimeout(() => {
                            ref.current?.focus();
                            ref.current?.setSelectionRange(value.length, value.length);
                        }, 0);
                    }}
                >
                    <EyeIcon fill={showPassword ? '#4A7A8A' : '#adb4bd'} />
                </button>
            </div>
        </div>
    );
}

interface Props extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    value: string;
    onChange: (val: string, event: React.ChangeEvent<HTMLInputElement>) => void;
    type: 'default' | 'error';
}

export default PasswordInput;
