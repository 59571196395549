import React from 'react';

import classnames from 'classnames/bind';

import styles from './SSO.module.scss';

const cx: CX = classnames.bind(styles);

function SSO() {
    return (
        <div className={cx('sso')}>
            <a
                className="clear-button"
                href="https://dev.mt-emea-dmt.apexdigital.online/api/v1/login/"
            >
                SSO (sign in via corporate)
            </a>
        </div>
    );
}

export default SSO;
