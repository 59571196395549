import React, { useEffect, useRef } from 'react';

import { useSearchParams } from 'react-router-dom';

import classnames from 'classnames/bind';

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import * as yup from 'yup';
import { FieldErrors, useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import useAuth from '@HOOKS/useAuth';

import { notify } from '@NOTIFICATION/Notificator';

import SSO from '@PAGES/common/login/components/SSO';
import EmailInput from '@PAGES/common/login/components/EmailInput';
import PasswordInput from '@PAGES/common/login/components/PasswordInput';
import SignInButton from '@PAGES/common/login/components/SignInButton';
import HiddenFormButton from '@COMPONENTS/COMMON/buttons/HiddenFormButton';

import styles from './LoginForm.module.scss';

const cx: CX = classnames.bind(styles);

const schema = yup.object({
    email: yup.string().trim().email('Email is not valid').required('Email is required'),
    password: yup.string().trim().required('Password is required'),
});

type Schema = yup.InferType<typeof schema>;

function LoginForm() {
    const [search] = useSearchParams();

    const formButtonRef = useRef<HTMLButtonElement>(null);

    const {
        actions,
        state: {
            loginData: {
                isLoading, isError, error,
            },
        },
    } = useAuth();

    useEffect(() => {
        if (isError) {
            if ((error as FetchBaseQueryError)?.status === 403) {
                notify.error('Wrong email or password!', {
                    toastId: 'wrong-email-or-token',
                });
            } else {
                notify.error('Something wrong!', {
                    toastId: 'something-wrong',
                });
            }
        }
    }, [isError, error]);

    const { handleSubmit, control } = useForm<Schema>({
        defaultValues: {
            email: search.get('email') || '',
            password: search.get('token') || '',
        },
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const {
        field: email, //
        fieldState: { error: emailError },
    } = useController({
        name: 'email',
        control,
    });

    const {
        field: password, //
        fieldState: { error: passwordError },
    } = useController({
        name: 'password',
        control,
    });

    function onValid(values: Schema) {
        actions.login({
            email: values.email,
            token: values.password,
        });
    }

    function onInvalid(errors: FieldErrors<Schema>) {
        console.log(errors);
    }

    return (
        <div className={cx('login-form')}>
            <div className={cx('content')}>
                <div className={cx('title')}>
                    Please Sign In
                </div>
                <div className={cx('sso-wrapper')}>
                    <SSO />
                </div>
                <div className={cx('or')}>
                    or
                </div>
                <form
                    onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();

                        if (!isLoading) {
                            handleSubmit(onValid, onInvalid)(event);
                        }
                    }}
                >
                    <div className={cx('input-wrapper')}>
                        <EmailInput
                            value={email.value}
                            type={emailError ? 'error' : 'default'}
                            onChange={(val: string) => {
                                email.onChange(val);
                            }}
                        />
                        <div className={cx('error-message')}>
                            {emailError?.message ? emailError.message : ''}
                        </div>
                    </div>
                    <div className={cx('input-wrapper')}>
                        <PasswordInput
                            value={password.value}
                            type={passwordError ? 'error' : 'default'}
                            onChange={(val: string) => {
                                password.onChange(val);
                            }}
                        />
                        <div className={cx('error-message')}>
                            {passwordError?.message ? passwordError.message : ''}
                        </div>
                    </div>
                    <HiddenFormButton ref={formButtonRef} />
                </form>

                <SignInButton
                    isProcessing={isLoading}
                    onClick={() => {
                        formButtonRef.current?.click();
                    }}
                />
            </div>
        </div>
    );
}

export default LoginForm;
