import React from 'react';

import classnames from 'classnames/bind';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';
import styles from './SignInButton.module.scss';

const cx: CX = classnames.bind(styles);

function SignInButton({
    onClick,
    isProcessing,
    disabled,
    ...attributes
}: Props) {
    function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        if (!disabled) {
            onClick(event);
        }
    }

    return (
        <div
            className={cx('sign-in-button')}
        >
            {
                isProcessing ? (
                    <div className={cx('processing')}>
                        <CircularProgressIndicator
                            size={24}
                        />
                    </div>
                ) : (

                    <button
                        type="button"
                        className="clear-button"
                        disabled={disabled}
                        onClick={handleClick}
                        {...attributes}
                    >
                        Sign In
                    </button>
                )
            }
        </div>
    );
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isProcessing: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default SignInButton;
