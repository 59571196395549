import React from 'react';

import classnames from 'classnames/bind';

import Brand from '@PAGES/common/login/components/Brand';
import LoginForm from 'src/view/PAGES/common/login/components/LoginForm';

import styles from './Login.module.scss';

const cx: CX = classnames.bind(styles);

function LoginPage() {
    return (
        <div className={cx('page')}>
            <div className={cx('brand-wrapper')}>
                <Brand />
            </div>
            <div className={cx('form-wrapper')}>
                <div />

                <LoginForm />

                <div className={cx('terms')}>
                    Apex Digital GmbH
                    {' '}
                    {new Date().getFullYear()}
                    {' '}
                    <a href="https://apexdigital.online">Terms</a>
                    {' '}
                    |
                    {' '}
                    <a href="https://apexdigital.online">Privacy</a>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
